<style scoped lang="scss">
.analysis {
  width: 100%;
  height: 100%;

  .analysis-show {
    width: 100%;
    height: calc(100% - 118px);
    margin-top: 18px;

    .title {
      text-align: center;
      font-size: 36px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #474747;
    }
  }
}

.analysis-report-content {
  margin-top: 40px;

  .analysis-report-title {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #474747;
    position: relative;
    text-indent: 35px;
  }

  .analysis-report-title::after {
    content: "";
    width: 5px;
    height: 18px;
    background-color: #6340c8;
    border-radius: 2.5px;
    position: absolute;
    left: 20px;
    top: 1px;
  }
}

.analysis-report {
  padding: 0 35px;

  .analysis-report-left {
    margin-top: 29px;
    padding: 35px 65px;
    background-color: #f6f7fc;
    border-radius: 10px;

    .box-card {
      border-radius: 10px;
      transition: all 0.4s;
    }

    .box-card:hover {
      box-shadow: 2px 5px 10px #ccc;
    }

    .box-card:nth-child(1) {
      //   background-image: radial-gradient(#f07f7c, #eb6866);
    }
  }

  .analysis-report-right {
    margin-top: 29px;
    padding: 20px 0;
    box-sizing: border-box;
    margin-left: 40px;
    background-color: #f6f7fc;
    border-radius: 10px;
  }
}

.box-card {
  min-width: 14.38vw;
  padding: 2.08vw 1.73vw;
  box-sizing: border-box;
  color: #ffffff;
  position: relative;

  .box-card-img {
    width: 3.75vw;
    height: 3.75vw;
    border-radius: 1.04vw;
    position: absolute;
    top: 2.2vw;
    right: 1.88vw;
    text-align: center;
    line-height: 4.75vw;
  }

  .box-card-data {
    font-size: 1.72vw;
    font-weight: 600;
  }

  p {
    margin-top: 0.63vw;
    font-size: 0.8vw;
  }
}

.box-card.card1 {
  background-image: radial-gradient(#f17b7f, #f16256);

  .box-card-img {
    background-color: #e07263;
    box-shadow: 1px 3px 20px 10px #e7584e;
  }
}

.box-card.card2 {
  background-image: radial-gradient(#63d182, #4ec973);

  .box-card-img {
    background-color: #4cb76a;
    box-shadow: 1px 3px 20px 10px #46b86c;
  }
}

.box-card.card3 {
  background-image: radial-gradient(#9787ec, #8476ef);

  .box-card-img {
    background-color: #8a70ec;
    box-shadow: 1px 3px 20px 10px #7a6ce4;
  }
}

.box-card.card4 {
  background-image: radial-gradient(#feb948, #feb237);

  .box-card-img {
    background-color: #feaf2f;
    box-shadow: 1px 3px 20px 10px #f7a22a;
  }
}

// 班级成绩
.class-grade {
  margin-top: 50px;

  .class-grade-title {
    text-indent: 35px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #474747;
    position: relative;
  }

  .class-grade-title::after {
    content: "";
    width: 5px;
    height: 18px;

    background-color: #6340c8;
    border-radius: 2.5px;
    position: absolute;
    left: 20px;
    top: 1px;
  }
}

// 年级成绩demo-form-inline
.demo-form-inline {
  padding: 23px 35px;
}

// 班级数据

.class-data-title {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #474747;
  position: relative;
  text-indent: 40px;
  margin-top: 44px;
}

.class-data-title::after {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #feaf2f;
  position: absolute;
  top: 2px;
  left: 0;
  border-radius: 50%;
}

.class-data-title::before {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #432a8c;
  position: absolute;
  top: 2px;
  left: 9px;
  border-radius: 50%;
}

// 班级数据
.class-data {
  width: 100%;
  height: 20.57vw;

  &-wrapper {
    width: 100%;
    min-height: 500px;
    margin-top: 24px;
  }

  .class-data-left {
    width: 32%;
    height: 20.57vw;
    background: #f6f7fc;
    border-radius: 10px;
    position: relative;

    .water-polo {
      width: 100%;
      height: 20.57vw;
      padding: 20px;
      box-sizing: border-box;
      margin-top: -20px;
    }

    .echarts-label {
      width: 100%;
      display: flex;
      position: absolute;
      bottom: 90px;
      left: 0;
      padding: 0 20px;
      box-sizing: border-box;

      p {
        width: 50%;
        text-align: center;
        font-size: 24px;
        color: #333;
      }
    }
  }

  .class-data-center {
    width: 23%;
    height: 20.57vw;
    background: #f6f7fc;
    border-radius: 10px;
    margin-left: 30px;
    position: relative;

    .pass_rate {
      width: 100%;
      height: 100%;
      padding: 20px;
      box-sizing: border-box;
    }

    .rate-num {
      font-size: 30px;
      color: #f66478;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: default;

      span {
        font-size: 14px;
      }
    }
  }

  .class-data-right {
    width: 45%;
    height: 20.57vw;
    background: #f6f7fc;
    border-radius: 10px;
    margin-left: 30px;

    .rugosa-rose {
      padding: 20px;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
    }
  }
}

.line-chart {
  width: 100%;
  height: 380px;
  margin-top: 60px;
}

::v-deep {
  .el-card__body {
    padding: 0;
  }

  .demo-form-inline .el-input--medium .el-input__inner {
    // height: 50px;
    width: 15vw;
    border-radius: 10px;
    line-height: 50px;
    border: none;
    background: #f6f7fc;
  }

  .demo-form-inline .el-input--medium .el-input__inner::placeholder {
    color: #acabab;
  }

  .el-range-editor--medium.el-input__inner {

    border-radius: 10px;
    border: none;
    background: #f6f7fc;
  }

  .el-form--inline .el-form-item {
    margin-right: 36px;
  }

  .el-range-editor--medium .el-range-separator {

    background: #f6f7fc;
  }

  .el-range-editor--medium .el-range__icon,
  .el-range-editor--medium .el-range__close-icon {
    background: #f6f7fc;
  }

  .el-range-editor--medium .el-range-input {
    background: #f6f7fc;
  }

  // .el-form-item--medium .el-form-item__label {
  //   line-height: 50px;
  // }
  .el-form-item--medium .el-form-item__content {
    // line-height: 50px;
  }

}

.no-data {
  width: 100%;
  height: calc(100% - 72px);
  background: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &--empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      color: #555;
      margin-top: 10px;
    }
  }
}
</style>
<template>
  <section class="analysis">
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item label="学校">
        <el-select v-model="schoolId" placeholder="选择学校" @change="changeSchool">
          <el-option v-for="item in schoolList" :key="item.value" :label="item.key" :value="item.value" />
        </el-select>
      </el-form-item>
      <!-- <el-form-item>
        <el-button type="primary">查询</el-button>
      </el-form-item> -->
    </el-form>
    <!-- 展示区域 -->
    <div class="analysis-show" :class="{ 'no-data': $isEmpty(schoolStatisticsData) }" v-loading="schoolDataLoad">
      <template v-if="!$isEmpty(schoolStatisticsData) && !schoolDataLoad">
        <div class="title">{{ schoolStatisticsData.scsch_name }}</div>
        <!-- 学校统计数据 -->
        <div class="analysis-report-content">
          <div class="analysis-report-title">数据汇报</div>
          <div class="analysis-report flex">
            <div class="analysis-report-left flex1">
              <el-row :gutter="40">
                <el-col :span="12">
                  <el-card class="box-card card1">
                    <div class="box-card-data">
                      <countTo :startVal="0" :endVal="schoolStatisticsData.stuNum || 0" :duration="3000"></countTo>
                      <p>人数汇报</p>
                    </div>
                    <div class="box-card-img">
                      <img src="../../assets/images/people-num.png" alt="" />
                    </div>
                  </el-card>
                </el-col>
                <el-col :span="12">
                  <el-card class="box-card card2">
                    <div class="box-card-data">
                      <countTo :startVal="0" :endVal="schoolStatisticsData.classNum || 0" :duration="3000"></countTo>
                      <p>班级数量</p>
                    </div>
                    <div class="box-card-img">
                      <img src="../../assets/images/people-num.png" alt="" />
                    </div>
                  </el-card>
                </el-col>
              </el-row>

              <el-row :gutter="40" style="margin-top: 40px">
                <el-col :span="12">
                  <el-card class="box-card card3">
                    <div class="box-card-data">
                      <countTo :startVal="0" :endVal="schoolStatisticsData.teaNum || 0" :duration="3000"></countTo>
                      <p>师资力量</p>
                    </div>
                    <div class="box-card-img">
                      <img src="../../assets/images/people-num.png" alt="" />
                    </div>
                  </el-card>
                </el-col>
                <el-col :span="12">
                  <el-card class="box-card card4">
                    <div class="box-card-data">
                      <countTo :startVal="0" :endVal="schoolStatisticsData.couwareNum || 0" :duration="3000"></countTo>
                      <p>课程数量</p>
                    </div>
                    <div class="box-card-img">
                      <img src="../../assets/images/people-num.png" alt="" />
                    </div>
                  </el-card>
                </el-col>
              </el-row>
            </div>
            <div class="analysis-report-right flex1">
              <div ref="grade_echart" style="width: 100%; height: 100%"></div>
            </div>
          </div>
        </div>
        <!-- 班级统计数据 -->
        <div class="class-grade">
          <div class="class-grade-title">班级成绩</div>
          <div class="demo-form-inline">
            <el-form :inline="true" :model="searchForm">
              <el-form-item label="班级" prop="sccla_id">
                <el-cascader v-model="searchForm.sccla_id" placeholder="选择班级" :options="classTree"
                  :props="{ value: 'key', label: 'label' }" />
              </el-form-item>
              <el-form-item label="科目">
                <el-select v-model="searchForm.sysub_id" placeholder="选择科目">
                  <el-option v-for="item in subjectList" :key="item.sysub_id" :label="item.sysub_name"
                    :value="item.sysub_id" />
                </el-select>
              </el-form-item>
              <el-form-item label="成绩类型">
                <el-select v-model="searchForm.type" placeholder="选择成绩类型">
                  <el-option label="考试成绩" :value="10" />
                  <el-option label="作业成绩" :value="20" />
                  <el-option label="课堂成绩" :value="30" />
                </el-select>
              </el-form-item>
              <el-form-item label="时间">
                <el-date-picker v-model="searchForm.time" type="daterange" range-separator="-" start-placeholder="开始日期"
                  end-placeholder="结束日期" :picker-options="pickerOptions">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="getClassStatistics">查询</el-button>
              </el-form-item>
            </el-form>
            <div class="class-data-title">班级数据</div>
            <div class="class-data-wrapper" :class="{ 'no-data': $isEmpty(classStatisticsData) }"
              v-loading="classDataLoad">
              <template v-if="!$isEmpty(classStatisticsData) && !classDataLoad">
                <div class="class-data flex justify-between">
                  <div class="class-data-left">
                    <div class="water-polo" ref="water_polo"></div>
                    <div class="echarts-label">
                      <p>人数汇总</p>
                      <p>{{ searchForm.type == 10 && '考试' || searchForm.type == 20 && '作业' || '做题' }}次数</p>
                    </div>
                  </div>
                  <div class="class-data-center">
                    <div class="pass_rate" ref="pass_rate"></div>
                    <p class="rate-num">
                      {{ classStatisticsData.passRate || 0 }}<span>%</span>
                    </p>
                  </div>
                  <div class="class-data-right" :class="{ 'no-data': $isEmpty(classStatisticsData.paperData) }">
                    <div class="rugosa-rose" ref="rugosa_rose" v-show="!$isEmpty(classStatisticsData.paperData)"></div>
                    <div class="no-data--empty" v-if="$isEmpty(classStatisticsData.paperData)">
                      <img src="@assets/images/no-data.png" width="280" alt="">
                      <p>暂无统计数据哦~</p>
                    </div>
                  </div>
                </div>
                <div class="line-chart" ref="line_chart" v-show="searchForm.type != 30"></div>
              </template>
              <div class="no-data--empty" v-if="$isEmpty(classStatisticsData) && !classDataLoad">
                <img src="@assets/images/no-data.png" alt="">
                <p>暂无统计数据哦~</p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="no-data--empty" v-if="$isEmpty(schoolStatisticsData) && !schoolDataLoad">
        <img src="@assets/images/no-data.png" alt="">
        <p>暂无统计数据哦~</p>
      </div>
    </div>
  </section>
</template>
<script>
import { $schoolList, $statisticsSchool, $statisticsClass } from "@api/dyf_statistics"
import { getSchClassTreeList } from "@api/SchSchool";
import { $getSubject } from "@api/common"
import countTo from "vue-count-to";
import "echarts-liquidfill/src/liquidFill.js";

import { loopgraph, rosegraph } from "@/utils/allecharts"
export default {
  name: "",
  components: { countTo },
  data() {
    return {
      schoolId: {},
      schoolList: [], // 学校列表
      classTree: [], // 班级数据
      subjectList: [], // 科目数据
      searchForm: {
        type: 10,
      }, // 班级统计数据筛选条件
      schoolStatisticsData: {}, // 学校统计数据
      classStatisticsData: {}, // 班级统计数据
      schoolDataLoad: false,
      classDataLoad: false,
      timeOptionRange: '',
      /* 时间控件选择控制 */
      pickerOptions: {
        disabledDate: (time) => {
          let timeOptionRange = this.timeOptionRange;
          let secondNum = 60 * 60 * 24 * 7 * 1000;
          let currentYear = new Date().getFullYear();
          if (timeOptionRange) {
            let minSeven = time.getTime() > timeOptionRange.getTime() - secondNum && time.getTime() < timeOptionRange.getTime() + secondNum;
            return time.getFullYear() != currentYear || minSeven;
          }
          return
        },
        onPick: (time) => {
          //当第一时间选中才设置禁用当第一时间选中才设置禁用
          if (time.minDate && !time.maxDate) {
            this.timeOptionRange = time.minDate;
          }
          if (time.maxDate) {
            this.timeOptionRange = null;
          }
        }
      },
    };
  },

  created() {
    this.getSchoolList();
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.resizeEcharts();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.resizeEcharts();
    });
  },
  methods: {
    /** 获取可选学校列表 */
    async getSchoolList() {
      this.schoolDataLoad = true;
      let { data } = await $schoolList();
      this.schoolList = [...data] || [];
      if (!this.$isEmpty(data)) {
        this.schoolId = data[0].value;
        this.getClassTree();
        this.getSchoolStatistics();
      } else {
        this.schoolDataLoad = false;
      }
      this.$forceUpdate();
    },
    /** 获取班级数据 */
    async getClassTree() {
      this.classDataLoad = true;
      let { data } = await getSchClassTreeList(this.schoolId);
      data = data.filter(item => !this.$isEmpty(item.children));
      this.classTree = [...data];
      if (!this.$isEmpty(data)) {
        let grade = data[0];
        this.searchForm = {
          ...this.searchForm,
          sccla_id: [grade.key, grade.children[0].key]
        };
        this.getSubject();
      } else {
        this.classDataLoad = false;
      }
    },
    /** 获取科目数据 */
    async getSubject() {
      this.classDataLoad = true;
      let { data } = await $getSubject();
      this.subjectList = [...data];
      if (!this.$isEmpty(data)) {
        this.searchForm = {
          ...this.searchForm,
          sysub_id: data[0].sysub_id
        };
        this.getClassStatistics();
      } else {
        this.classDataLoad = false;
      }
    },
    /** 获取学校统计数据 */
    async getSchoolStatistics() {
      this.schoolDataLoad = true;
      let { data } = await $statisticsSchool(this.schoolId);
      this.schoolDataLoad = false;
      if (data.gradeData.constructor !== Array) {
        data.gradeData = Object.keys(data.gradeData).map((key) => data.gradeData[key])
      }
      this.schoolStatisticsData = data;
      this.$nextTick(() => {
        this.initSchoolEcharts();
        this.$forceUpdate();
      })
    },
    /** 获取学校班级统计数据 */
    async getClassStatistics() {
       this.classDataLoad = true;
      let { time } = this.searchForm;
      let params = { ...this.searchForm, sccla_id: this.searchForm.sccla_id[1], scsch_id: this.schoolId };
      if (time) {
        params.start_time = new Date(time[0]).getTime() / 1000;
        params.end_time = new Date(time[1]).getTime() / 1000;
      }
      delete params.time;
      let { data } = await $statisticsClass(params);
      this.classDataLoad = false;
      this.classStatisticsData = data;
      this.$nextTick(() => {
        this.initClassEcharts();
        this.$forceUpdate();
      })
    },
    /** 切换学校 */
    changeSchool() {
      this.getClassTree();
      this.getSchoolStatistics();
    },
    /** 更新页面图表 */
    resizeEcharts() {
      const echartsArr = ['line_chart', 'rugosa_rose', 'pass_rate', 'water_polo', 'grade_echart'];
      echartsArr.map(item => {
        let el = this.$refs[item];
        this.$echarts.init(el).resize();
      });
      this.$forceUpdate();
    },
    /** 实例化学校数据相关图表 */
    initSchoolEcharts() {
      let echarts = this.$echarts.init(this.$refs['grade_echart']);
      let { gradeData } = this.schoolStatisticsData, data = [];
      gradeData.map(item => {
        let data_item = {
          value: item.num,
          name: item.grade_name
        }
        data.push(data_item)
      })
      let option = loopgraph(data);
      echarts.setOption(option);
    },
    /** 实例化班级数据相关图表 */
    initClassEcharts() {
      const echartsKeys = ['line_chart', 'rugosa_rose', 'pass_rate', 'water_polo'];
      let echartsArr = new Array(4);
      let statistics = this.classStatisticsData;
      let { paperData, score } = statistics;
      echartsKeys.map((item, index) => {
        echartsArr[index] = this.$echarts.init(this.$refs[item]);
        echartsArr[index].resize();
        echartsArr[index].clear();
        let option, data;
        switch (item) {
          // 平均数据
          case 'line_chart':
            let xAxisData = paperData.map(item => item.name);
            let yAxisData = paperData.map(item => item.class);
            let yAxisData2 = paperData.map(item => item.grade);
            option = {
              backgroundColor: "#E8EAF3",
              tooltip: {
                trigger: "axis",
              },
              legend: {
                orient: "vertical",
                top: "center",
                right: "0%",
                icon: "stack",
                textStyle: {
                  color: "#3f3f3f",
                  fontSize: 14,
                },
              },
              grid: {
                top: "15%",
                left: "50",
                right: "10%",
                bottom: "15%",
              },
              xAxis: [
                {
                  type: "category",
                  axisLine: {
                    lineStyle: {
                      color: "#ffffff",
                    },
                  },
                  splitArea: {
                    lineStyle: {
                      color: "red",
                    },
                  },
                  axisLabel: {
                    color: "#666666",
                  },
                  splitLine: {
                    show: true,
                  },
                  boundaryGap: true,
                  data: xAxisData,
                },
              ],
              yAxis: [
                {
                  type: "value",
                  min: 0,
                  splitNumber: 4,
                  splitLine: {
                    show: true,
                    lineStyle: {
                      color: "#ECEEF5",
                    },
                  },
                  axisLine: {
                    lineStyle: {
                      color: "#ffffff",
                    },
                    show: true,
                  },
                  axisLabel: {
                    show: true,
                    margin: 20,
                    textStyle: {
                      color: "#666666",
                    },
                  },
                  axisTick: {
                    show: false,
                  },
                },
              ],
              series: [
                {
                  name: "班级平均",
                  type: "line",
                  showAllSymbol: true,
                  symbol: "circle",
                  symbolSize: 15,
                  lineStyle: {
                    normal: {
                      color: "#23BF8B",
                      shadowColor: "rgba(0, 0, 0, .3)",
                      shadowBlur: 0,
                      shadowOffsetY: 5,
                      shadowOffsetX: 5,
                    },
                  },
                  label: {
                    show: true,
                    position: "top",
                    textStyle: {
                      color: "#23BF8B",
                    },
                  },
                  itemStyle: {
                    color: "#23BF8B",
                    borderColor: "#ffffff",
                    borderWidth: 3,
                    shadowColor: "rgba(0, 0, 0, .3)",
                    shadowBlur: 0,
                    shadowOffsetY: 2,
                    shadowOffsetX: 2,
                  },
                  tooltip: {
                    show: true,
                  },
                  data: yAxisData,
                },

              ],
              dataZoom: [
                {
                  type: "slider", //滑动条型数据区域缩放组件
                  realtime: true, //拖动时，是否实时更新系列的视图。如果设置为 false，则只在拖拽结束的时候更新。
                  height: 4,
                  endValue: 11, //数据窗口范围的结束数值。如果设置了 dataZoom-inside.end 则 endValue 失效
                  fillerColor: "rgba(17, 100, 210, 0.4)", // 滚动条颜色
                  borderColor: "rgba(17, 100, 210, 0.1)",
                  handleSize: 0, // 两边手柄尺寸
                  showDetail: false, // 拖拽时是否展示滚动条两侧的文字
                  top: "96%", //组件离容器上侧的距离
                  zoomLock: true, // 是否只平移不缩放
                },
                {
                  type: "inside", //内置型数据区域缩放组件
                  endValue: 7, // 最多12个
                  zoomOnMouseWheel: false, // 关闭鼠标滚轮缩放
                  moveOnMouseWheel: false, // 开启鼠标滚轮窗口平移
                  moveOnMouseMove: true, // 开启鼠标移动窗口平移
                },
              ],
            };
            break;
          // 班级数据
          case 'rugosa_rose':
            data = [];
            score.map(item => {
              let data_item = {
                name: item.name,
                value: item.num
              }
              data.push(data_item)
            });
            option = rosegraph(data)
            break;
          // 考试及格率
          case 'pass_rate':
            option = {
              title: [
                {
                  text: this.searchForm.type == 10 && '考试及格率' || this.searchForm.type == 20 && '作业完成率' || '做题正确率',
                  x: "center",
                  bottom: "5%",
                  textStyle: {
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#474747",
                  },
                },
              ],
              series: [
                {
                  name: "circle",
                  type: "pie",
                  clockWise: true,
                  radius: ["50%", "66%"],
                  itemStyle: {
                    normal: {
                      label: {
                        show: false,
                      },
                      labelLine: {
                        show: false,
                      },
                    },
                  },
                  hoverAnimation: false,
                  data: [
                    {
                      value: statistics.passRate || 0,
                      name: "占比",
                      itemStyle: {
                        normal: {
                          color: {
                            // 颜色渐变
                            colorStops: [
                              {
                                offset: 0,
                                color: "#F66478", // 0% 处的颜色
                              },
                              {
                                offset: 1,
                                color: "#F66478", // 100% 处的颜色1
                              },
                            ],
                          },
                          label: {
                            show: false,
                          },
                          labelLine: {
                            show: false,
                          },
                        },
                      },
                    },
                    {
                      name: "剩余",
                      value: 100 - statistics.passRate,
                      itemStyle: {
                        normal: {
                          color: "#F6F0F5",
                        },
                      },
                    },
                  ],
                },
              ],
            };
            break;
          // 人数汇总 || 考试次数
          case 'water_polo':
            option = {
              series: [
                {
                  type: "liquidFill",
                  radius: "40%",
                  center: ["25%", "45%"],
                  itemStyle: {
                    opacity: 0.95,
                    shadowColor: "#F6F7FC",
                  },
                  color: [
                    {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: "#FBB242",
                        },
                      ],
                      globalCoord: false,
                    },
                  ],
                  data: [0.4, 0.45, 0.5],
                  backgroundStyle: {
                    borderWidth: 1,
                    color: "#F6F7FC",
                  },
                  label: {
                    normal: {
                      position: ["50%", "80%"],
                      textStyle: {
                        fontSize: 24,
                        color: "#ffffff",
                      },
                      formatter: () => {
                        return statistics.UserAll || 0
                      },
                    },
                  },
                  outline: {
                    show: true,
                    borderDistance: 1,
                    itemStyle: {
                      borderWidth: 2,
                      borderColor: "#fff",
                    },
                  },
                },
                {
                  type: "liquidFill",
                  radius: "40%",
                  center: ["75%", "45%"],
                  itemStyle: {
                    opacity: 0.95,
                    shadowColor: "#F6F7FC",
                  },
                  color: [
                    {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: "#5DC175",
                        },
                      ],
                      globalCoord: false,
                    },
                  ],
                  data: [0.4, 0.45, 0.5],
                  backgroundStyle: {
                    borderWidth: 0,
                    color: "#F6F7FC",
                  },
                  label: {
                    normal: {
                      position: ["50%", "80%"],
                      textStyle: {
                        fontSize: 24,
                        color: "#fff",
                      },
                      formatter: () => {
                        return statistics.paperNum || 0
                      },
                    },
                  },
                  outline: {
                    show: true,
                    borderDistance: 1,
                    itemStyle: {
                      borderWidth: 2,
                      borderColor: "#fff",
                    },
                  },
                },
              ],
            };
            break;
          default:
            break;
        };
        echartsArr[index].setOption(option);
      })
    },
  },
};
</script>
